import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'homepage',
  initialState: {
    isRefresh: false
  },
  reducers: {
    addIsRefresh: (state, action)=>{
      state.isRefresh = action.payload;
    }
  }
});

export const { addIsRefresh } = homeSlice.actions;

export default homeSlice.reducer;
