import request from 'Chores/axiosConfig';
import { objectToQueryParams } from 'Chores/utils';
import store from 'store';

const API_ROOT = process.env.REACT_APP_API_ROOT;

const APIS = {
  RETRO: '/retro',
  RETROLIST: '/retro/retroList',
  RETRO_RECORD: '/retroRecord',
  RETRORECORD: '/retro/retroRecord',
  RETRORECORDLIST: '/retro/retroRecordList',
  RETROKUDOS: '/retro/retroKudos',
  RETROATTEND: '/retro/retroAttend'
};

export function getSprintList(projectId) {
  return request.get(`${API_ROOT}${APIS.RETRO}/availableReleases/${projectId}`, {
    headers: { __project: projectId }
  });
}

// retro
export function getRetroList(params) {
  return request.get(`${API_ROOT}${APIS.RETROLIST}${objectToQueryParams(params)}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function addRetro(params) {
  return request.post(`${API_ROOT}${APIS.RETRO}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function getRetro(id) {
  return request.get(`${API_ROOT}${APIS.RETRO}/${id}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function updateRetro(id, params) {
  return request.put(`${API_ROOT}${APIS.RETRO}/${id}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function deleteRetro(id) {
  return request.delete(`${API_ROOT}${APIS.RETRO}/${id}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

// record
export function getRetroActionList(params) {
  return request.get(`${API_ROOT}${APIS.RETRO}/actionPlanList${objectToQueryParams(params)}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function getRetroRecordList(retroId) {
  return request.get(`${API_ROOT}${APIS.RETRORECORDLIST}/${retroId}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function addRetroRecord(params) {
  return request.post(`${API_ROOT}${APIS.RETRORECORD}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function updateRetroRecord(params) {
  return request.put(`${API_ROOT}${APIS.RETRORECORD}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function deleteRetroRecord(id) {
  return request.delete(`${API_ROOT}${APIS.RETRORECORD}/${id}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function deleteRetroRecordByType(retroId, type) {
  return request.delete(`${API_ROOT}${APIS.RETRO}/retroRecordByType/${retroId}?type=${type}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

// kudos
export function getMemberKudosList(projectId, retroId) {
  return request.get(`${API_ROOT}${APIS.RETRO}/retroMemberKudos?retroId=${retroId}&projectId=${projectId}`, {
    headers: { __project: projectId }
  });
}

export function addRetroKudos(params) {
  return request.post(`${API_ROOT}${APIS.RETROKUDOS}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function updateRetroKudos(params) {
  return request.put(`${API_ROOT}${APIS.RETROKUDOS}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function deleteRetroKudos(id) {
  return request.delete(`${API_ROOT}${APIS.RETROKUDOS}/${id}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

// attend
export function getRetroAttendList(retroId) {
  return request.get(`${API_ROOT}${APIS.RETRO}/retroAttendList/${retroId}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function getRetroAttendListWithoutCache(retroId) {
  return request.get(`${API_ROOT}${APIS.RETRO}/retroAttendListWithoutCache/${retroId}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function addRetroAttend(params) {
  return request.post(`${API_ROOT}${APIS.RETROATTEND}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

export function updateRetroAttend(params) {
  return request.put(`${API_ROOT}${APIS.RETROATTEND}`, params, {
    headers: { __project: store.getState().retro.projectId }
  });
}

// update data check
export function getRetroDataIsNeedUpdate(params) {
  return request.get(`${API_ROOT}${APIS.RETRO}/retroDataIsNeedUpdate${objectToQueryParams(params)}`, {
    headers: { __project: store.getState().retro.projectId }
  });
}
