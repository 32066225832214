import axios from 'axios';
import { TIME_OUT, BASE_URL } from './config';
import { getFromSessionStorage } from 'Chores/utils';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  withCredentials: true,
  headers: {
    __tenant: 'DashboardPlus',
    __project: null
  }
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getFromSessionStorage('token')}`;
    return config;
  },
  (err) => {
    err.message = 'Server exception';
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  },
  (err) => {
    if (err.response.status === 401) {
      window.location.href= '/';
    } else if (err.response.status === 403) {
      err.message = 'Permission denied';
    }
    VegaNotify.open({
      type: 'error',
      message: err.message
    });
    return Promise.reject(err.response);
  }
);

export default instance;
