import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    menus: [],
    currentPage: '',
    loginUserId: ''
  },
  reducers: {
    addMenus: (state, action) => {
      state.menus = action.payload;
    },
    addCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    addLoginUserId: (state, action) => {
      state.loginUserId = action.payload;
    }
  }
});

export const { addMenus, addCurrentPage, addLoginUserId } = commonSlice.actions;

export default commonSlice.reducer;
