import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VegaFlex, VegaChip, VegaButton, VegaCard } from '@heartlandone/vega-react';
import { SPRINT_STATUS_CODE } from 'Chores/Constants';
import PropTypes from 'prop-types';
import { deleteMenuQuickActions } from 'Chores/request';
import { FE_ROUTES } from 'Chores/Api';
import '../Home.scss';

const CardItem = ({ project = {}, quickActions = [], onOpenModal }) => {
  const menus = useSelector((state) => state.common.menus);
  const [initAddNewButtonDisplay, setInitAddNewButtonDisplay] = useState(true);
  const [isChipShowCloseIcon, setIsChipShowCloseIcon] = useState(false);
  const [quickActionDisplay, setQuickActionDisplay] = useState([]);
  const [removeQuickActions, setRemoveActions] = useState([]);
  const [quickActionDeletedList, setQuickActionDeletedList] = useState([]);
  const [gpList, setGpList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setCardQuickActions();
    getGpList();
  }, [quickActions]);
  const DEFAULTSTATUSCODE = 1;

  const getGpList = () => {
    if (menus.length > 0) {
      const gpList = menus.find((item) => item.key === 'dev')?.children?.find((dev) => dev.key === 'gp')?.children;
      setGpList(gpList);
    }
  };

  const goCurrentSprintDetail = (e) => {
    const sprintId = project.sprintInfo.id;
    const arr = gpList.find((item) => item.referenceId === project.projectId)?.children;
    const obj = arr?.length > 0 && arr.find((item) => item.key === 'sprint-grid');
    obj && navigate(`${FE_ROUTES.HOME}${obj.newPath}/sprint-details`, {
      state: {
        sprintId: sprintId
      }
    });
  };

  const setCardQuickActions = () => {
    const cardQuickActionsCopy = [];
    const arr = quickActions.filter((item) => !item.isDeleted);
    setQuickActionDeletedList(arr);
    arr.map((item) => {
      if (item.projectId === project.projectId) {
        cardQuickActionsCopy.push(item);
      }
      setQuickActionDisplay(cardQuickActionsCopy);
    });
  };
  const removeChips = (action) => {
    removeQuickActions.push(action.id);
    setRemoveActions(removeQuickActions);

    const cloneQuickActions = quickActionDisplay.map((item, index) => {
      return Object.assign(item, {});
    });
    cloneQuickActions.map((item) => {
      if (item.id === action.id) {
        item.isDeleted = true;
      }
    });
    setQuickActionDisplay(cloneQuickActions);
  };

  const editQuickAction = () => {
    setIsChipShowCloseIcon(true);
    setInitAddNewButtonDisplay(false);
  };
  const saveCard = () => {
    setIsChipShowCloseIcon(false);
    setInitAddNewButtonDisplay(true);
    deleteMenuQuickActions({ idList: removeQuickActions }).then((res) => {
      VegaNotify.open({
        type: 'success',
        message: 'Delete operation completed successfully'
      });
    }).catch((error) => {
      VegaNotify.open({
        type: 'failed',
        message: 'Delete operation failed to complete'
      });
    });
    setRemoveActions([]);
    setCardQuickActions();
  };
  const cancelCard = () => {
    setIsChipShowCloseIcon(false);
    setInitAddNewButtonDisplay(true);
    const cloneQuickActions = quickActionDisplay.map((item, index) => {
      return Object.assign(item, {});
    });
    cloneQuickActions.map((item) => {
      removeQuickActions.map((id) => {
        if (item.id === id) {
          item.isDeleted = false;
        }
      });
    });
    setQuickActionDisplay(cloneQuickActions);
  };
  const getSprintStatusName = (statusCode = DEFAULTSTATUSCODE) => {
    if (statusCode === SPRINT_STATUS_CODE.YELLOW) {
      return 'Y';
    } else if (statusCode === SPRINT_STATUS_CODE.RED) {
      return 'R';
    }
    return 'G';
  };

  const getSprintStatusOption = (statusCode) => {
    const config = {
      text: getSprintStatusName(statusCode),
      variant: 'filled-background',
      size: 'small',
      bgColor: 'bg-accent7-primary',
      textColor: 'text-inverted-primary'
    };
    if (statusCode === SPRINT_STATUS_CODE.YELLOW) {
      config.text = getSprintStatusName(statusCode);
      config.bgColor = 'bg-accent10-primary';
    } else if (statusCode === SPRINT_STATUS_CODE.RED) {
      config.text = getSprintStatusName(statusCode);
      config.bgColor = 'bg-danger';
    }
    return config;
  };

  const vegaChips = () => {
    return quickActionDisplay.map((action) => {
      return action.isDeleted == false ? (
        <div className="v-mt-size-8 v-mr-size-12 v-inline-block" key={action.id}>
          <VegaChip
            text={action.customizeName}
            size="extra-small"
            textColor="text-black"
            variant={isChipShowCloseIcon? 'outlined': 'filled-background'}
            clickable={true}
            showCloseIcon={isChipShowCloseIcon}
            bgColor={isChipShowCloseIcon? 'bg-primary': 'bg-tertiary'}
            onVegaClick={() => {
              if (isChipShowCloseIcon) {
                return false;
              } else {
                const arr = gpList.find((item) => item.referenceId === action.projectId)?.children;
                const obj = arr?.length > 0 && arr.find((item) => item.id === action.menuNodeId);
                obj && navigate(`${FE_ROUTES.HOME}${obj.newPath}`);
              };
            }}
            onVegaClose={() => removeChips(action)}
          ></VegaChip>
        </div>
      ) : null;
    });
  };

  return (
    <VegaCard variant="shadow">
      <VegaFlex direction={'col'} justifyContent="space-between" className="v-h-full">
        <div className="v-pt-size-24 v-pb-size-16 v-px-size-24">
          <div className="v-text-primary v-font-h5">{project.projectName}</div>
          <div className="v-font-field-label v-text-secondary companyName">{project.companyName}</div>
          <div className="v-mt-size-16 v-px-size-16 v-py-size-24  project-status">
            <VegaFlex direction={'row'} justifyContent="space-between">
              <div className="status_width">
                <div className="v-font-field-label-sm v-text-secondary">Status</div>
                <div className="v-py-size-8">
                  {project.sprintInfo && project.sprintInfo.statusColor ? (
                    <div className={`status-outline ${getSprintStatusName(project.sprintInfo.statusColor)}`}>
                      <VegaChip {...getSprintStatusOption(project.sprintInfo.statusColor)}></VegaChip>
                    </div>
                  ) : (
                    <div className="v-font-field-label-em v-text-primary">--</div>
                  )}
                </div>
              </div>
              <div className="status_width">
                <div className="v-font-field-label-sm v-text-secondary">Current Sprint</div>
                {project.sprintInfo && project.sprintInfo.name?
                  <VegaFlex>
                    <a className="vega-link v-py-size-8  v-font-field-label-em v-block" onClick={(e) => goCurrentSprintDetail(project)}>{project.sprintInfo.name}</a>
                    <span className ={`${project.sprintInfo.isHaveChangeRequest ? 'CR-Signal' : 'display-none'}`}>CR</span>
                  </VegaFlex>: '--'}
              </div>
              <div>
                <div className="v-font-field-label-sm v-text-secondary">Current Sprint Days</div>
                <div className="v-text-right v-font-field-value-em v-text-primary v-py-size-8">
                  {project.sprintInfo?.currentDays ?? '0'}
                </div>
              </div>
            </VegaFlex>
          </div>
        </div>
        <div className={`v-pt-size-8 v-pl-size-24 v-pb-size-24 ${isChipShowCloseIcon ? 'chip-bg' : ''}`}>
          {vegaChips()}
          {initAddNewButtonDisplay ? (
            <div
              className={`${
                quickActionDeletedList.length > 0 && quickActionDisplay.length > 0 ? 'v-pt-size-24 ' : ''
              } ${isChipShowCloseIcon ? 'chip-bg' : ''}`}
            >
              <VegaFlex>
                <VegaChip
                  text="Add New"
                  size="extra-small"
                  bgColor="bg-primary"
                  textColor="text-black"
                  variant="outlined"
                  clickable={true}
                  icon="plus-sign"
                  onVegaClick={onOpenModal}
                ></VegaChip>
                {quickActionDeletedList.length > 0 && quickActionDisplay.length > 0 ? (
                  <>
                    <span className="vertical-line"></span>
                    <span className="edit-pen" onClick={(event) => editQuickAction(event, project)}>
                      <i className="fa-regular fa-pen-to-square"></i>
                    </span>
                  </>
                ) : (
                  <div className="v-hidden"></div>
                )}
              </VegaFlex>
            </div>
          ) : (
            <div className="v-hidden"></div>
          )}
          {isChipShowCloseIcon ? (
            <div className="v-mr-size-24 v-pt-size-24">
              <VegaFlex direction={'row'} gap={'size-12'} justifyContent={'end'} useNativeFlex={true}>
                <VegaButton
                  size="small"
                  label="Cancel"
                  variant="secondary"
                  onVegaClick={(e) => cancelCard()}
                ></VegaButton>
                <VegaButton size="small" label="Save" onVegaClick={(e) => saveCard()}></VegaButton>
              </VegaFlex>
            </div>
          ) : null}
        </div>
      </VegaFlex>
    </VegaCard>
  );
};
CardItem.propTypes = {
  quickActions: PropTypes.array,
  project: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    companyName: PropTypes.string,
    sprintInfo: PropTypes.object,
    currentDays: PropTypes.number
  }),
  onOpenModal: PropTypes.func
};
export default CardItem;
