import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const retroSlice = createSlice({
  name: 'retro',
  initialState: {
    projectId: '',
    sprintsSource: [],
    retroList: [],
    pagination: {
      maxHasGetDataPage: 0,
      totalCount: 0,
      page: 1
    },
    dateFilter: {
      type: '3M',
      value: [
        moment(new Date()).subtract(3, 'months').format('MM/DD/YYYY'),
        moment(new Date()).format('MM/DD/YYYY 23:59:59')
      ]
    },
    attends: [],
    isBack: false,
    showActionModal: false,
    currentRetro: {},
    // prevent redirect to detail again when back from detail opened from share link
    hasRedirectRetroDetail: false
  },
  reducers: {
    setSprintsSource: (state, action) => {
      state.sprintsSource = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setRetroList: (state, action) => {
      state.retroList = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    setAttends: (state, action) => {
      state.attends = action.payload;
    },
    setIsBack: (state, action) => {
      state.isBack = action.payload;
    },
    setShowActionModal: (state, action) => {
      state.showActionModal = action.payload;
    },
    setCurrentRetro: (state, action) => {
      state.currentRetro = action.payload;
    },
    setHasRedirectRetroDetail: (state, action) => {
      state.hasRedirectRetroDetail = action.payload;
    }
  }
});

export const { setSprintsSource, setProjectId, setRetroList, setPagination, setDateFilter, setAttends, setIsBack, setShowActionModal, setCurrentRetro, setHasRedirectRetroDetail } = retroSlice.actions;

export default retroSlice.reducer;
