/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { VegaButtonLink, VegaFlex, VegaAppHeaderButton, VegaPopover, VegaButton, VegaBox } from '@heartlandone/vega-react';
import { useKeycloak } from '@react-keycloak/web';
import { REDIRECT_URI_ROOT } from 'Chores/Api';
import { firstUpperCase, breadcrumbHandle, emailConvertLabel, getDefaultUserAvatar, dateTimeFormat, getFromlocalStorage, copyToClipboard, objectToQueryParams, setFromlocalStorage } from 'Chores/utils';
import './header.scss';
import UserEditProfileModal from './UserEditProfileModal';
import { getUserInfoAvatar, updateUserLogout } from 'Chores/request';
import { setShowActionModal } from 'Slices/Retro/retroSlice';
import { RETRO_STATUS } from 'Chores/Constants';
import { downloadRetroPdf } from 'Containers/Retro/export';

const Header = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const [breadcrumb, setBreadcrumb] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const menus = useSelector((state) => state.common.menus);
  const title = location.pathname.split('/').filter((item) => item);
  const [isUserEditProfileModalOpened, setIsUserEditProfileModalOpened] = useState(false);
  const [userAvatar, setUserAvatar] = useState('');
  const [userInfoAvatar, setUserInfoAvatar] = useState({
    id: '',
    userId: '',
    name: '',
    phone: '',
    email: '',
    avatar: ''
  });
  const currentPage = useSelector((state) => state.common.currentPage);
  const currentRetro = useSelector((state) => state.retro.currentRetro);
  const dateFilter = useSelector((state) => state.retro.dateFilter);
  const pagination = useSelector((state) => state.retro.pagination);
  const [allUserAvatar, setAllUserAvatar] = useState({});

  useEffect(() => {
    if (['retroDetail', 'retroHome'].includes(currentPage)) {
      const avatarData = getFromlocalStorage('allUserAvatar');
      setAllUserAvatar(avatarData);
    }
  }, [currentPage]);

  useEffect(() => {
    setBreadcrumb(breadcrumbHandle(location.pathname, menus));
  }, [location, menus]);

  useEffect(() => {
    if (keycloak?.idTokenParsed?.sub !== undefined) {
      loadUserInfoAvatar();
    }
  }, [keycloak?.idTokenParsed]);

  const loadUserInfoAvatar = () => {
    getUserInfoAvatar(keycloak.idTokenParsed.sub).then((res) => {
      if (!res.name || res.name.trim() === '') {
        res.name = emailConvertLabel(keycloak?.idTokenParsed?.email);
      }

      if (res.avatar === null || res.avatar === '') {
        setUserAvatar(getDefaultUserAvatar());
      } else {
        setUserAvatar(res.avatar);
      }

      setUserInfoAvatar(res);
    });
  };

  const onLoginOut = () => {
    setFromlocalStorage('loginRedirect', '');
    updateUserLogout();
    keycloak.logout({ redirectUri: REDIRECT_URI_ROOT });
  };

  const toggleRetroActionModal = (open) => {
    dispatch(setShowActionModal(open));
  };

  const onCopyRetroDetailLink = () => {
    const params = {
      redirect: 'retro-detail',
      projectId: currentRetro.projectId,
      retroId: currentRetro.id,
      dateFilterType: dateFilter.type,
      startTime: dateFilter.value[0],
      endTime: dateFilter.value[1],
      page: pagination.page
    };
    const url = process.env.REACT_APP_HOST + location.pathname.split('/retro-detail')[0] + objectToQueryParams(params);
    copyToClipboard(url, 'Link copied to clipboard');
  };

  const onDownloadRetro = () => {
    downloadRetroPdf(currentRetro);
  };

  const retroDetailLeftHtml = () => {
    return (
      <div className={currentPage === 'retroDetail' ? 'v-ml-size-12' : 'v-hidden'}>
        <div className='v-font-h5'>{currentRetro.name}</div>
        <div className='v-font-field-label-sm v-text-secondary'>Created by {allUserAvatar[currentRetro.userId]?.name} | {currentRetro.status == RETRO_STATUS.CLOSED ? 'Closed' : 'Created'}: {dateTimeFormat(currentRetro.status == RETRO_STATUS.CLOSED ? currentRetro.endTime : currentRetro.startTime, 'DD MMM YYYY')}</div>
      </div>
    );
  };

  const retroDetailRightHtml = () => {
    return (
      <VegaFlex alignItems='center' gap={'size-12'}>
        <VegaAppHeaderButton avatar={{ icon: 'fas fa-tasks' }} size='small' onVegaClick={() => toggleRetroActionModal(true)} className={['retroDetail', 'retroHome'].includes(currentPage) ? '' : 'v-hidden'}></VegaAppHeaderButton>
        <VegaAppHeaderButton avatar={{ icon: 'fas fa-download' }} size='small' className={currentPage === 'retroDetail' ? '' : 'v-hidden'} onVegaClick={() => onDownloadRetro()}></VegaAppHeaderButton>
        <VegaAppHeaderButton avatar={{ icon: 'fas fa-external-link' }} size='small' className={currentPage === 'retroDetail' ? '' : 'v-hidden'} onVegaClick={() => onCopyRetroDetailLink()}></VegaAppHeaderButton>
      </VegaFlex>
    );
  };

  const toggle = () => {
    const leftNav = document.querySelector('vega-left-nav');
    leftNav.toggle();
  };

  return (
    <VegaFlex gap="size-16" direction="row" alignItems="center" justifyContent="space-between">
      <VegaFlex justifyContent="start" alignItems="center">
        <VegaAppHeaderButton
          avatar={{
            icon: 'hb-menu'
          }}
          onVegaClick={toggle}
        ></VegaAppHeaderButton>
        <VegaBox className={currentPage == 'retroDetail' ? 'v-hidden' : ''} padding={{ 'y': 'size-8', 'x': 'size-8', 'left': 'size-12' }}>
          <h1 className="v-font-h4 vega-flex-shrink-0">
            {breadcrumb[breadcrumb.length - 1]?.name || firstUpperCase(title[title.length - 1].replace(/[-/_]/g, ' '))}
          </h1>
          <ul className={{ 'vega-flex-shrink-0': true, 'v-mt-size-8': breadcrumb.length > 0 }}>
            {breadcrumb.map(({ title, link }, index) => (
              <React.Fragment key={index}>
                <li
                  className={`${index === breadcrumb.length - 1 ? 'v-text-black' : 'v-text-link'
                    } v-font-p2-short v-float-left`}
                >
                  {index !== breadcrumb.length - 1 ? (
                    <VegaButtonLink onVegaClick={() => navigate(`${link}`)} label={title}></VegaButtonLink>
                  ) : (
                    title
                  )}
                </li>
                {index !== breadcrumb.length - 1 ? (
                  <li className="v-px-size-8 v-text-black v-font-p2-short v-float-left">|</li>
                ) : null}
              </React.Fragment>
            ))}
          </ul>
        </VegaBox>
        {retroDetailLeftHtml()}
      </VegaFlex>
      <VegaFlex alignItems='center' gap={'size-12'}>
        {retroDetailRightHtml()}
        <div className="vega-flex-shrink-0 capitalize">
          <VegaPopover
            id="user-profile-popover"
            size="380"
            placement="bottom"
            alignment="end"
          >
            <VegaAppHeaderButton
              slot='popover-content'
              id="VegaAppHeaderButton"
              avatar={{ src: userAvatar }}
              label={userInfoAvatar.name}
            />
            <div slot='popover' className="user-popover">
              <img className="user-image" src={userAvatar}></img>
              <p className="user-name">{userInfoAvatar.name}</p>
              <p className="user-email v-text-secondary">{userInfoAvatar.email}</p>
              <p className="user-phone"><span>Phone Number</span><span className="v-pl-size-12">{userInfoAvatar.phone.trim() === '' ? '______' : userInfoAvatar.phone}</span></p>
              <div className="v-w-full v-border-0 v-border-b v-border-solid v-border-color-divider v-mb-size-16 v-mt-size-32"></div>
              <VegaButtonLink
                size="small"
                icon="settings"
                icon-type="icon"
                icon-align="left"
                label="Edit Profile"
                onVegaClick={(e) => setIsUserEditProfileModalOpened(true)}
              ></VegaButtonLink>
              <div className="v-w-full v-border-0 v-border-b v-border-solid v-border-color-divider v-mb-size-24 v-mt-size-16"></div>
              <div className="user-signout">
                <VegaButton
                  variant="secondary"
                  size="small"
                  onVegaClick={(e) => onLoginOut()}
                >
                  Sign Out
                </VegaButton>
              </div>
            </div>
          </VegaPopover>
        </div>
      </VegaFlex>
      <UserEditProfileModal
        userInfoAvatar={userInfoAvatar}
        isOpened={isUserEditProfileModalOpened}
        onClose={setIsUserEditProfileModalOpened}
        loadUserInfoAvatar={loadUserInfoAvatar}
      />
    </VegaFlex>
  );
};

export default Header;
