import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: process.env.REACT_APP_TOKEN_API,
  realm: process.env.REACT_APP_KEYCLOAK_REAMLS,
  clientId: process.env.REACT_APP_CLIENT_ID
});

export default keycloak;

// response_type: code
// client_id: dashboard-client
// state: R0hDVmVCa3ROfmFNa2lIfmZ-YjZfQmI4UkNOWWdyVEFBcU11MTZCdGszeFY0
// redirect_uri: https://dashboardrelease.gprddigital.com/
// scope: openid offline_access
// code_challenge: htvrO5AosVxniFpwC6aecN3O_w60N0_kv_9YTBOVGP0
// code_challenge_method: S256
// nonce: R0hDVmVCa3ROfmFNa2lIfmZ-YjZfQmI4UkNOWWdyVEFBcU11MTZCdGszeFY0
