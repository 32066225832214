import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    token: ''
  },
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
    }
  }
});

export const { saveToken } = appSlice.actions;

export default appSlice.reducer;
