import { configureStore } from '@reduxjs/toolkit';
import appReducer from './Slices/App/appSlice';
import headerReducer from './Slices/Header/headerSlice';
import homeReducer from './Slices/Home/homeSlice';
import commonSlice from './Slices/commonSlice';
import retroReducer from 'Slices/Retro/retroSlice';
import permissionReducer from 'Slices/Permission/permissionSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    header: headerReducer,
    home: homeReducer,
    common: commonSlice,
    retro: retroReducer,
    permission: permissionReducer
  }
});
