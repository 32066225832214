import { getFromlocalStorage, dateTimeFormat } from 'Chores/utils';
import { getProjectBasicInfo } from 'Chores/request';
import { getMemberKudosList, getRetroRecordList, getRetroAttendListWithoutCache, getRetroActionList } from './service';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { RETRO_ACTIONPLAN_STATUS } from 'Chores/Constants';

function summaryTable(doc, option, retro, projectInfo) {
  autoTable(doc, {
    startY: 30,
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor, textColor: option.textColor },
    columns: [
      { header: { content: 'Date', styles: { valign: 'middle', cellWidth: 25 } }, dataKey: 'date' },
      { header: { content: dateTimeFormat(retro.createTime), styles: { valign: 'middle', cellWidth: 50, fontStyle: 'normal' } }, dataKey: 'dateValue' },
      { header: { content: 'Team', styles: { valign: 'middle', cellWidth: 30 } }, dataKey: 'team' },
      { header: { content: projectInfo.projectName, styles: { valign: 'middle', fontStyle: 'normal' } }, dataKey: 'teamValue' }
    ],
    theme: 'grid'
  });
}

function participantsTable(doc, option, participantsName) {
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY,
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor, textColor: option.textColor },
    columns: [
      { header: { content: 'Participants', styles: { valign: 'middle', cellWidth: 25 } }, dataKey: 'participants' },
      { header: { content: participantsName.join('; '), styles: { valign: 'middle', fontStyle: 'italic' } }, dataKey: 'participantValue' }
    ],
    theme: 'grid'
  });
}

function wellTable(doc, option, body, startY) {
  autoTable(doc, {
    startY: startY,
    headStyles: { fillColor: 'FFEFC8', textColor: option.textColor },
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor },
    tableLineWidth: 0,
    body: body,
    columns: [
      { header: { content: 'What Went Well', styles: { valign: 'middle' } }, dataKey: 'content' },
      { header: { content: 'Name', styles: { cellWidth: option.nameCellWidth } }, dataKey: 'user' }
    ],
    theme: 'grid'
  });
}

function wrongTable(doc, option, body) {
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 5,
    headStyles: { fillColor: 'FFE7DF', textColor: option.textColor },
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor },
    tableLineWidth: 0,
    body: body,
    columns: [
      { header: { content: 'What Went Wrong', styles: { valign: 'middle' } }, dataKey: 'content' },
      { header: { content: 'Name', styles: { cellWidth: option.nameCellWidth } }, dataKey: 'user' }
    ],
    theme: 'grid'
  });
}

function suggestionsTable(doc, option, body) {
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 5,
    headStyles: { fillColor: 'E3DEFF', textColor: option.textColor },
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor },
    tableLineWidth: 0,
    body: body,
    columns: [
      { header: { content: 'Suggestions', styles: { valign: 'middle' } }, dataKey: 'content' },
      { header: { content: 'Name', styles: { cellWidth: option.nameCellWidth } }, dataKey: 'user' }
    ],
    theme: 'grid'
  });
}

function actionPlanTable(doc, option, body) {
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 5,
    headStyles: { fillColor: 'E0F9F7', textColor: option.textColor },
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor },
    tableLineWidth: 0,
    body: body,
    columns: [
      { header: { content: 'Action Items', styles: { valign: 'middle' } }, dataKey: 'content' },
      { header: { content: 'Status', styles: { valign: 'middle', cellWidth: 20 } }, dataKey: 'status' },
      { header: { content: 'Assignee', styles: { cellWidth: option.nameCellWidth } }, dataKey: 'user' }
    ],
    theme: 'grid'
  });
}

function kudosTable(doc, option, body, startY) {
  autoTable(doc, {
    startY: startY,
    headStyles: { fillColor: 'E5E5E5', textColor: option.textColor },
    styles: { fillColor: option.fillColor, lineWidth: option.lineWidth, lineColor: option.textColor },
    tableLineWidth: 0,
    body: body,
    columns: [
      { header: { content: 'To', styles: { valign: 'middle', cellWidth: option.nameCellWidth } }, dataKey: 'to' },
      { header: { content: 'From', styles: { valign: 'middle', cellWidth: option.nameCellWidth } }, dataKey: 'from' },
      { header: 'Comments', dataKey: 'comment', styles: { halign: 'center' } }
    ],
    theme: 'grid'
  });
}

export function downloadRetroPdf(retro) {
  Promise.all([getMemberKudosList(retro.projectId, retro.id), getRetroRecordList(retro.id), getRetroAttendListWithoutCache(retro.id), getProjectBasicInfo(retro.projectId),
    getRetroActionList({
      projectId: retro.projectId,
      status: RETRO_ACTIONPLAN_STATUS.ALL,
      isExport: true,
      maxResultCount: 1000
    })
  ]).then((res) => {
    const memberKudos = res[0];
    const recordList = res[1];
    const retroAttends = res[2];
    const projectInfo = res[3];
    const actionPlanList = res[4].items;
    const allUserAvatar = getFromlocalStorage('allUserAvatar') || {};
    allUserAvatar.all = { id: 'all', name: 'Team' };

    const doc = new JsPDF();
    const docOption = { textColor: '333333', fillColor: 'ffffff', lineWidth: 0.5, nameCellWidth: 30 };
    doc.setFontSize(22);
    doc.text(retro.name, 105, 15, null, null, 'center');
    doc.setFontSize(18);
    doc.text('Summary', 15, 25);
    summaryTable(doc, docOption, retro, projectInfo);

    const participantsName = [];
    retroAttends.forEach((attend) => {
      participantsName.push(allUserAvatar[attend.userId]?.name);
    });
    participantsTable(doc, docOption, participantsName);

    let startY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(18);
    doc.text('Retrospective', 15, startY);

    // Well table
    const wellBody = [];
    recordList?.well?.forEach((item) => {
      wellBody.push({ content: item.content, user: allUserAvatar[item.createUserId]?.name });
    });
    wellTable(doc, docOption, wellBody, startY + 5);

    // Wrong table
    const wrongBody = [];
    recordList?.wrong?.forEach((item) => {
      wrongBody.push({ content: item.content, user: allUserAvatar[item.createUserId]?.name });
    });
    wrongTable(doc, docOption, wrongBody);

    // Suggestions table
    const suggestionBody = [];
    recordList?.suggestion?.forEach((item) => {
      suggestionBody.push({ content: item.content, user: allUserAvatar[item.createUserId]?.name });
    });
    suggestionsTable(doc, docOption, suggestionBody);

    // actionPlan table
    actionPlanList?.sort((a, b) => {
      return b.status - a.status;
    });
    const actionPlanBody = [];
    actionPlanList?.forEach((item) => {
      actionPlanBody.push({ content: item.content, status: item.status == RETRO_ACTIONPLAN_STATUS.RESOLVED ? 'Closed' : 'Open', user: allUserAvatar[item.assignTo]?.name });
    });
    actionPlanTable(doc, docOption, actionPlanBody);

    // kudos table
    const kudosBody = [];
    memberKudos.forEach((item) => {
      if (item.kudos.length) {
        item.kudos.forEach((kudosItem) => {
          kudosBody.push({ comment: kudosItem.content, to: { content: item.name, rowSpan: item.kudos.length }, from: allUserAvatar[kudosItem.fromUserId]?.name });
        });
      }
    });
    startY = doc.lastAutoTable.finalY + 10;
    doc.text('Kudos', 15, startY);
    kudosTable(doc, docOption, kudosBody, startY + 5);

    doc.save(`Retrospective_${projectInfo.projectName}_${dateTimeFormat(retro.createTime, 'YYYYMMDD')}.pdf`);
  });
}
