import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMenuQuickActions, createMenuQuickAction } from 'Chores/request';
import { VegaModal, VegaFlex, VegaInput, VegaButton, VegaForm, VegaInputSelect } from '@heartlandone/vega-react';
import PropTypes from 'prop-types';

const ActionAddModal = ({
  userId = '',
  project = {},
  open = false,
  quickActions = [],
  setCardQuickActions = () => { },
  onClose = () => { }
}) => {
  const menus = useSelector((state) => state.common.menus);
  const vegaForm = useRef();
  const [quickActionSelectList, setQuickActionSelectList] = useState([]);
  const [selectDisplayValue, setDisplaySelectValue] = useState('');
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
  const [currentCardQuickActions, setCurrentCardQuickActions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    getSelectMenuList();
    vegaForm.current.reset();
    setDisabledSaveBtn(false);
    getCurrentCardQuickActions();
    setErrorMessage(false);
  }, [open]);

  const getSelectMenuList = () => {
    if (menus.length > 0) {
      const gpList = menus.find((item) => item.key === 'dev')?.children?.find((dev) => dev.key === 'gp')?.children;
      const selectList = gpList?.length > 0 && gpList.find((item) => item.referenceId === project.projectId)?.children;
      const currentSelectList = selectList && selectList.map((item) => ({ displayName: item.label, id: item.id }));
      setQuickActionSelectList(currentSelectList);
    }
  };

  const getCurrentCardQuickActions = () => {
    const cardQuickActionsCopy = [];
    const arr = quickActions.filter((item) => !item.isDeleted);
    arr.map((item) => {
      if (item.projectId === project.projectId) {
        cardQuickActionsCopy.push(item);
      }
      setCurrentCardQuickActions(cardQuickActionsCopy);
    });
  };

  return (
    <VegaModal
      open={open}
      id={'actionAddModal'}
      size={'480'}
      modalTitle="Add Quick Action"
      padding="size-24"
      backdrop={'static'}
      onVegaClose={() => {
        onClose(false);
      }}
    >
      <VegaForm ref={vegaForm}>
        <VegaInputSelect
          label={'Quick Action'}
          placeholder={'Select an available action as a quick action'}
          size={'small'}
          required={true}
          source={quickActionSelectList}
          data-vega-form={'menuNodeId'}
          onVegaChange={(e) => {
            const value = e.currentTarget.value;
            const selectValue = quickActionSelectList && quickActionSelectList.length > 0 && quickActionSelectList.find((item) => item.id === value);
            setDisplaySelectValue(selectValue?.displayName);
            vegaForm.current.getValue().then((value) => {
              const sameAction = currentCardQuickActions.length > 0 && currentCardQuickActions.find((item) => item.menuNodeId === value.menuNodeId);
              if (!sameAction) {
                setErrorMessage(false);
                setDisabledSaveBtn(false);
              }
            });
          }}
        ></VegaInputSelect>
        <div className="v-mt-size-24">
          <VegaInput
            label={'Customize Name'}
            placeholder={'e.g. Project Home'}
            size={'small'}
            value={selectDisplayValue}
            required={true}
            onVegaChange={(e) => setDisplaySelectValue(e.currentTarget.value) }
            data-vega-form={'customizeName'}
          ></VegaInput>
          { errorMessage? <div className='v-text-error v-font-field-label-xs-em  v-mt-size-8'>Please do not add the same quick action</div> : null}
        </div>
        <div className="v-pt-size-4 v-pb-size-2 v--mr-size-16">
          <VegaFlex direction={'row'} gap={'size-12'} margin={'size-16'} justifyContent={'end'} useNativeFlex={true}>
            <VegaButton
              label={'Cancel'}
              size={'small'}
              variant={'secondary'}
              onVegaClick={(e) => {
                onClose(false);
              }}
            ></VegaButton>
            <VegaButton
              label={'Save'}
              size={'small'}
              disabled={disabledSaveBtn}
              onVegaClick={() => {
                vegaForm.current.valid().then((formRes) => {
                  if (formRes.isValid) {
                    setDisabledSaveBtn(true);
                    vegaForm.current.getValue().then((value) => {
                      const sameAction = currentCardQuickActions.length > 0 && currentCardQuickActions.find((item) => item.menuNodeId === value.menuNodeId);
                      if (sameAction) {
                        setErrorMessage(true);
                        return false;
                      } else {
                        const data = {
                          projectId: project.projectId,
                          menuNodeId: value.menuNodeId,
                          customizeName: value.customizeName,
                          userId: userId
                        };
                        createMenuQuickAction(data).then(() => {
                          getMenuQuickActions(userId).then((res) => {
                            setCardQuickActions(res.items);
                            VegaNotify.open({
                              type: 'success',
                              message: 'Save operation completed successfully'
                            });
                          }).catch((error) => {
                            VegaNotify.open({
                              type: 'failed',
                              message: 'Save operation failed to complete'
                            });
                          });
                          onClose(false);
                        });
                      }
                    });
                  }
                });
              }}
            ></VegaButton>
          </VegaFlex>
        </div>
      </VegaForm>
    </VegaModal>
  );
};

export default ActionAddModal;

ActionAddModal.propTypes = {
  userId: PropTypes.string,
  project: PropTypes.object,
  quickActions: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setCardQuickActions: PropTypes.func
};
