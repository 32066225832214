import React, { useState, useEffect } from 'react';
import { VegaGrid } from '@heartlandone/vega-react';
import ActionAddModal from './ActionAddModal';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import { getUserInfoAvatar, getUserProjectsCurrentSprint, getMenuQuickActions } from 'Chores/request';
import './Home.scss';
import CardItem from './CardItem/CardItem';
import { getDictDataNameById, DICT_TYPE_CODE, getGreeting, capitalizedName } from 'Chores/utils';
import { VegaLoader } from '@heartlandone/vega';

const Home = () => {
  const { keycloak } = useKeycloak();
  const [isActionAddModalOpened, setIsActionAddModalOpened] = useState(false);
  const [project, setProject] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [quickActions, setCardQuickActions] = useState([]);
  const [userInfoAvatar, setUserInfoAvatar] = useState({
    userId: '',
    name: ''
  });
  document.body.id = 'body-id';

  useEffect(() => {
    if (keycloak?.idTokenParsed?.sub !== undefined) {
      loadUserInfoAvatar();
    }
  }, [keycloak?.idTokenParsed]);

  const formatTime = (timeString) => {
    return moment(timeString).format('dddd, MMMM D');
  };

  const getHomeGreeting = () => {
    const currentTime = new Date().getTime();
    const currentHour = new Date(currentTime).getHours();
    return getGreeting(currentHour);
  };

  const loadUserInfoAvatar = () => {
    getUserInfoAvatar(keycloak.idTokenParsed.sub).then((userInfo) => {
      setUserInfoAvatar(userInfo);
      getProjectList(userInfo.userId);
    });
  };

  const getProjectList = (id) => {
    const loadId = VegaLoader.load({
      containerSelector: '#body-id'
    });
    Promise.all([getUserProjectsCurrentSprint(id), getMenuQuickActions(id)]).then((res) => {
      res[0].length > 0 && res[0].map((obj) => {
        obj.companyName = obj.companyDictDataId ?
          getDictDataNameById(DICT_TYPE_CODE.CompanyName, obj.companyDictDataId) :
          '';
        if (obj.sprintInfo) {
          formatCurrentDays(obj.sprintInfo);
        }
      });
      setProjectList(res[0]);
      setCardQuickActions(res[1].items);
      VegaLoader.close(loadId);
    });
  };

  const formatCurrentDays= (sprintInfo) => {
    const startDate = new Date(sprintInfo.startDate);
    const today = new Date();
    const timeDiff = today.getTime() - startDate.getTime();
    let holidays = 0;
    sprintInfo.holidays?.split(',').forEach((day) => {
      if (today.getTime() >= new Date(day).getTime()) {
        holidays++;
      }
    });
    const currentDays = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));
    sprintInfo.currentDays = currentDays - holidays;
  };

  const onOpenModal = (project) => {
    setProject(project);
    setIsActionAddModalOpened(true);
  };

  return (
    <>
      <div className="home-container" id="home-container">
        <div className="v-font-h2 ">{getHomeGreeting()}, {capitalizedName(userInfoAvatar.name)}</div>
        <div>
          <span className="v-text-secondary v-font-h6">Here is your workshop for</span>
          <span className="v-text-primary v-font-h6 v-inline-block v-ml-size-8">
            {formatTime(new Date().toString())}
          </span>
        </div>
        <div className="v-mt-size-40">
          <VegaGrid column={2} row="1" gap="size-16">
            {projectList.map((project) => {
              return (
                <CardItem
                  project={project}
                  quickActions={quickActions}
                  key={project.projectId}
                  onOpenModal={() => onOpenModal(project)}
                ></CardItem>
              );
            })}
          </VegaGrid>
        </div>
      </div>
      <ActionAddModal
        userId={userInfoAvatar.userId}
        project={project}
        quickActions={quickActions}
        open={isActionAddModalOpened}
        setCardQuickActions={setCardQuickActions}
        onClose={setIsActionAddModalOpened}
      />
    </>
  );
};

export default Home;
