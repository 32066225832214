import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VegaModal, VegaFlex, VegaButton, VegaInput, VegaImageUploader } from '@heartlandone/vega-react';
import { blob2Base64, zoomImage, setAllUserAvatar } from 'Chores/utils';
import { updateUserInfoAvatar, createUserInfoAvatar } from 'Chores/request';

const UserEditProfileModal = ({ userInfoAvatar = {}, isOpened = false, onClose = () => { }, loadUserInfoAvatar = () => { } }) => {
  const [isSubmitInvalid, setIsSubmitInvalid] = useState(false);
  const userAvatarUploadRef = useRef();
  const [phone, setPhone] = useState('');

  useEffect(() => {
    setPhone(userInfoAvatar.phone.trim());
    setIsSubmitInvalid(false);
  }, [userInfoAvatar, isOpened]);

  const saveCompleted = () => {
    setAllUserAvatar();
    loadUserInfoAvatar();
    setIsSubmitInvalid(false);
    onClose(false);
  };

  const callbackByZoomImage = (avatar) => {
    const _userInfo = {
      id: userInfoAvatar.id,
      userId: userInfoAvatar.userId,
      phone: phone.trim(),
      avatar: avatar
    };

    if (userInfoAvatar.hasSet) {
      updateUserInfoAvatar(_userInfo).then((res) => {
        saveCompleted();
      });
    } else {
      createUserInfoAvatar(_userInfo).then((res) => {
        saveCompleted();
      });
    }
  };

  const _onSave = async () => {
    setIsSubmitInvalid(true);
    if (userAvatarUploadRef.current.value !== null) {
      const base64Image = await blob2Base64(userAvatarUploadRef.current.value.slice());
      zoomImage(base64Image, 100, callbackByZoomImage, 0.9);
    } else {
      callbackByZoomImage(userInfoAvatar.avatar);
    }
  };

  return (
    <VegaModal
      id="userEditProfileModal"
      open={isOpened}
      size="480"
      backdrop="static"
      modalTitle="Edit Profile"
      padding="size-32"
      onVegaClose={() => {
        onClose(false);
      }}
    >
      <VegaFlex direction={'col'} >
        <VegaImageUploader
          ref={userAvatarUploadRef}
          id="set-uploaded-image"
        ></VegaImageUploader>
        <VegaInput
          label="Phone Number"
          size='small'
          value={phone}
          type='text'
          max-length='15'
          onVegaChange={(e) => {
            setPhone(e.target.value);
          }}
        ></VegaInput>
        <VegaFlex
          direction={'row'}
          gap={'size-24'}
          margin={{ default: { top: 'size-40' } }}
          justifyContent={'end'}
          useNativeFlex={true}
        >
          <VegaButton
            size='small'
            label='Cancel'
            variant='secondary'
            onVegaClick={() => {
              onClose(false);
            }}
          >
          </VegaButton>
          <VegaButton
            size='small'
            label='Save'
            disabled={isSubmitInvalid}
            onVegaClick={_onSave}
          ></VegaButton>
        </VegaFlex>
      </VegaFlex>
    </VegaModal>
  );
};

UserEditProfileModal.propTypes = {
  userInfoAvatar: PropTypes.object,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  loadUserInfoAvatar: PropTypes.func
};

export default UserEditProfileModal;
