import { createSlice } from '@reduxjs/toolkit';
import { PERMISSION_ROLETYPE } from 'Chores/Constants';

export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    [`permissionsTreeData_${PERMISSION_ROLETYPE.PROJECT}`]: [],
    [`permissionsTreeData_${PERMISSION_ROLETYPE.MODULE}`]: []
  },
  reducers: {
    setModulePermissionsTreeData: (state, action) => {
      state[`permissionsTreeData_${PERMISSION_ROLETYPE.MODULE}`] = action.payload;
    },
    setProjectPermissionsTreeData: (state, action) => {
      state[`permissionsTreeData_${PERMISSION_ROLETYPE.PROJECT}`] = action.payload;
    }
  }
});

export const { setModulePermissionsTreeData, setProjectPermissionsTreeData } = permissionSlice.actions;

export default permissionSlice.reducer;
