import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { LOGINT_REDIRECT_URI_NEW } from 'Chores/Api';

const KeycloakAuth = () => {
  const { keycloak } = useKeycloak();
  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login({
        scope: 'openid offline_access',
        redirectUri: LOGINT_REDIRECT_URI_NEW
      });
    }
  });

  return (
    !!keycloak.authenticated && (
      <button type="button" onClick={() => keycloak.login({ redirectUri: LOGINT_REDIRECT_URI_NEW })}>
          Click here for sign
      </button>
    ));
};

export default KeycloakAuth;
